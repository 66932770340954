import React from 'react';
import { Table, List, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import PageHeader from '../../../components/PageHeader';
import Footer from '../../../components/Footer';
import Tabs from '../../../components/Tabs';
import abass from '../../../assets/images/departments/obgyn/abass.png';
import Adegbola from '../../../assets/images/departments/obgyn/Adegbola.png';
import Afolabi from '../../../assets/images/departments/obgyn/Afolabi.png';
import akinajo from '../../../assets/images/departments/obgyn/akinajo.png';
import anorlu from '../../../assets/images/departments/obgyn/anorlu.png';
import babah from '../../../assets/images/departments/obgyn/babah.png';
import Makwe from '../../../assets/images/departments/obgyn/Makwe.png';
import obot from '../../../assets/images/departments/obgyn/obot.png';
import okunade from '../../../assets/images/departments/obgyn/okunade.png';
import ohazurike from '../../../assets/images/departments/obgyn/ohazurike.png';
import okunowo from '../../../assets/images/departments/obgyn/okunowo.png';
import okunsanya from '../../../assets/images/departments/obgyn/okunsanya.png';
import olabisi from '../../../assets/images/departments/obgyn/olabisi.png';
import olamijulo from '../../../assets/images/departments/obgyn/olamijulo.png';
import Oluwole from '../../../assets/images/departments/obgyn/Oluwole.png';
import omisakin from '../../../assets/images/departments/obgyn/omisakin.png';
import owie from '../../../assets/images/departments/obgyn/owie.png';
import yinka from '../../../assets/images/departments/obgyn/yinka.png';

const OBGYN = () => {
  const subs = [
    {
      name: 'Epidemiology and Disease Control',
      services:
        'Disease surveillance activities, tuberculosis management in dots centre, vaccination activities, endemic disease control, tobacco control activities',
    },
    {
      name: 'Reproductive Health',
      services:
        'Well woman clinic, family planning, cancer screening, child welfare services',
    },
    {
      name: 'Public Health Nutrition',
      services: 'Nutrition clinic',
    },
    {
      name: 'Environmental Health',
      services: 'Technical expertise as required by management',
    },
    {
      name: 'Occupational Health',
      services: 'Technical expertise as required by management',
    },
    {
      name: 'Health Management',
      services: 'Quality assurance assessment as required by management',
    },
    {
      name: 'Primary Health Care',
      services: 'Rural health care services in model phc ',
    },
    {
      name: 'Rehabilitative Medicine',
      services: 'Geriatric clinic',
    },
  ];

  const services = [
    { name: 'Experimental And Maternal Medicine (EMM)' },
    { name: 'Oncological and Pathological Studies (OPS)' },
    { name: 'Reproductive Endocrinology and Fertility Regulation (REF)' },
    { name: 'Ultrasound and Fetal Medicine  (UFM)' },
  ];

  const subRows = subs.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
      <td>{element.services}</td>
    </tr>
  ));
  return (
    <Layout pageTitle='Department of Community Health & Primary Care | LUTH'>
      <NavOne />
      <PageHeader title='Department of Community Health & Primary Care' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Obstetrics and Gynaecology
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <p className='course-details__tab-text'>
                      The Department of Obstetrics and Gynaecology of the
                      College of Medicine, University of Lagos is composed of
                      academic and non-academic staff. Its main objective is to
                      train students towards the award of the M.B.B.S degree in
                      Medicine and Surgery. It has also been accredited by the
                      West African College of Surgeons (WACS) and National
                      Postgraduate Medical College of Nigeria (NPMCN) for the
                      training of Residents Doctors in Obstetrics and
                      Gynaecology. These Resident Doctors provide clinical
                      services to the Lagos University Teaching Hospital during
                      their clinical training. The Department is made up of four
                      units: (i) Experimental and Maternal Medicine (EMM) Unit,
                      (ii) Oncology and Pathological Studies (OPS) Unit, (iii)
                      Reproductive Endocrinology and Fertility Regulation (REF)
                      Unit, and (iv) Ultrasound and Fetal Medicine (UFM) Unit.
                      The Academic Staff of the Department provide clinical
                      services, engage in teaching and research in many areas,
                      both within and outside of their subspecialties and
                      collaborate with many other Departments in the Clinical as
                      well as Basic Sciences faculties of the College.
                      <br />
                      <br />
                      <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                        Vision
                      </h2>
                      To be a top class department for the pursuit of excellence
                      in knowledge and character development and to eradicate
                      maternal mortality among women in Obstetrics and
                      Gynaecology through teaching, research and clinical
                      practice
                      <br />
                      <br />
                      <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                        Mission
                      </h2>
                      To provide a conducive environment for teaching, learning,
                      research and development, where staff and students will
                      interact and compete effectively with other counterparts
                      globally and to ensure best reproductive health and
                      pregnant care for our women with excellent research and
                      medical programs with high quality and evidence based
                      clinical care.
                    </p>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olamijulo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Joseph A. Olamijulo</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, FMCOG, FRCOG, MD
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            HOD/Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Reproductive Endogy &
                            Fertility Regulation
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={anorlu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Rose I. Anorlu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, MPH, MD(Nig), FMCOG, FWACS, FRCOG
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Oncology & Pathological
                            Studies
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={Afolabi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Bosede B. Afolabi</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, DM (Notts), FRCOG, FWACS, FMCOG, MD.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Experimental & Maternal
                            Medicine
                          </p>
                        </div>
                      </div> 
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={omisakin} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Sunday I. Omisakin</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, MRCOG, MSC, DIVI,CICE
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Reproductive Endocrinology &
                            Fertility Regulation
                          </p>
                        </div>
                      </div>

                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={okunade} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Kehinde S. Okunade</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, FMCOG
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Oncology & Pathological
                            Studies
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={Adegbola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Omololu Adegbola</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MD, FWACS, FMCOG,.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Ultrasound & Fetal Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={Oluwole} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ayodeji A. Oluwole</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, FWACS, FMCOG,
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Experimental & Maternal
                            Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={okunsanya} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Babasola O. Okusanya</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MD, FMCOG, FWACS, MPH.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Experimental & Maternal
                            Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={Makwe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Christian C. Makwe</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MD, FWACS, FMCOG, MPH.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Reproductive Endocrinology &
                            Fertility Regulation
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={okunowo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Adeyemi A. Okunowo</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, FMCOG, MPH.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Oncology & Pathological
                            Studies
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={babah} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ochuwa A. Babah</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, FMCOG
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            General Obst. & Gynae., Ultrasound & Fetal Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={owie} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. E. Owie</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCOG
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Full Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Ultrasound And Fetal Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinajo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O. R. Akinajo</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, FWACS, FMCOG
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Full Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Experimental and Maternal Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ohazurike} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. E. Ohazurike</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS(IB), FMCOG, MSC
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Full Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Gynae-Oncology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={yinka} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Sumonu-Bolaji Yinka</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            FTC
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Chief Confidential Secretary
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={abass} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Abass A. K.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            HND
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Senior Executive Officer
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={obot} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr Edo-Obot M.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            GCE
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Chief Clerical Officer
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olabisi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Samuel Olabisi R.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            WAEC/Diploma
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Senior Data Assistant
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default OBGYN;
